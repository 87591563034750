// React
import React from "react"

// Gatsby
import {
    //Link,    
    graphql
} from "gatsby"

// Images
//import Image from "gatsby-image"

// MDX
import MdxWrapper from "../components/mdx/MdxWrapper"

// Site components
import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkExt from "../components/linkext"

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Styles
import "../styles/pages/ConsultationsPage.scss"

// Component for the consultations page
const ConsultationsPage = ({
    data,
    location
}) => {

    // MDX data for this page
    const pageData = data.mdx;

    // Frontmatter for this page
    const {
        title: pageTitle,
        category: pageCategory,
        // TODO: Replace this date in the md file with an automatic last updated date feature based on the file changed attribute ?
        date: pageDate,
        description: pageDescription,
    } = pageData.frontmatter;

    // Page thumbnail
    //let pageThumbnail = data.pageIllustration.childImageSharp.fluid;
    //let shareThumbnail = data.pageIllustration.childImageSharp.resize?.src;

    // Render
    return (
        <Layout>

            {/* SEO */}
            <SEO
                title={pageTitle}
                description={pageDescription}
                pathname={location.pathname}
                // thumbnail={shareThumbnail}
            />

            {/* Page */}
            <article id="consultations-page">

                {/* Page header */}
                <header className="page-header">

                    {/* Text on lhs */}
                    <section className="header-text">

                        {/* Page category */}
                        <div className="page-category">
                            {pageCategory}
                        </div>

                        {/* Page title */}
                        <h1 className="page-title">
                            {pageTitle}
                        </h1>

                        {/* Page description */}
                        <p className="page-description">
                            {pageDescription}
                        </p>

                    </section>

                    {/* Image on rhs */}
                    {/* <section className="page-image">
                        {pageThumbnail &&
                            <Image fluid={pageThumbnail} />
                        }
                    </section> */}

                </header>

                {/* Page main */}
                <main className="page-main">

                    {/* Page body */}
                    <section className="page-body">

                        {/* MDX */}
                        <MdxWrapper>
                            {pageData.body}
                        </MdxWrapper>

                        {/* Socials  */}
                        <div className="socials">

                            <h2>
                                Let's stay in touch
                            </h2>

                            <p>
                                To stay up to date with everything GameDevMix, and see where the journey leads next, I would love for you to check out my social profiles below:
                            </p>

                            <ul className="socials-list">

                                {/* YouTube */}
                                <li>
                                    <LinkExt to="https://www.youtube.com/channel/UC9V9OI9iAAa0p3HIy53Q5-g/?sub_confirmation=1">
                                        <FontAwesomeIcon
                                            icon={[ "fab", "youtube" ]}
                                            fixedWidth
                                        />
                                        <span>
                                            Subscribe to GameDevMix on <strong>YouTube</strong>
                                        </span>
                                    </LinkExt>
                                </li>

                                {/* Instagram */}
                                <li>
                                    <LinkExt to="https://instagram.com/GameDevMix"
                                    >
                                        <FontAwesomeIcon
                                            icon={[ "fab", "instagram" ]}
                                            fixedWidth
                                        />
                                        <span>
                                            Follow GameDevMix on <strong>Instagram</strong>
                                        </span>
                                    </LinkExt>
                                </li>

                                {/* Twitter */}
                                <li>
                                    <LinkExt to="https://twitter.com/GameDevMix">
                                        <FontAwesomeIcon
                                            icon={[ "fab", "twitter" ]}
                                            fixedWidth
                                        />
                                        <span>
                                            Follow GameDevMix on <strong>Twitter</strong>
                                        </span>
                                    </LinkExt>
                                </li>

                                {/* Facebook */}
                                <li>
                                    <LinkExt to="https://facebook.com/GameDevMix"
                                    >
                                        <FontAwesomeIcon
                                            icon={[ "fab", "facebook" ]}
                                            fixedWidth
                                        />
                                        <span>
                                            Like the GameDevMix <strong>Facebook</strong> page
                                        </span>
                                    </LinkExt>
                                </li>

                                {/* Pinterest */}
                                {/* <LinkExt to="https://pinterest.com/GameDevMix"
                                    >
                                        <FontAwesomeIcon 
                                            icon={["fab", "pinterest"]}
                                            fixedWidth
                                        />
                                        Follow GameDevMix on Pinterest
                                    </LinkExt> */}

                                {/* GitHub */}
                                {/* <LinkExt to="https://github.com/GameDevMix"
                                    >
                                        <FontAwesomeIcon 
                                            icon={["fab", "github"]}
                                            fixedWidth
                                        />
                                        Follow GameDevMix on GitHub
                                    </LinkExt> */}

                                {/* Patreon */}
                                {/* <LinkExt to="https://patreon.com/GameDevMix"
                                    >
                                        <FontAwesomeIcon 
                                            icon={["fab", "patreon"]}
                                            fixedWidth
                                        />
                                        Support GameDevMix on Patreon
                                    </LinkExt> */}

                                {/* RSS */}
                                <li>
                                    <LinkExt to="/rss.xml"
                                    >
                                        <FontAwesomeIcon
                                            icon={[ "fas", "rss" ]}
                                            fixedWidth
                                        />
                                        <span>
                                            Subscribe to the <strong>RSS feed</strong> for the GameDevMix blog
                                        </span>
                                    </LinkExt>
                                </li>

                            </ul>

                            <p>
                                Thank you for your time. I hope my little game development website can help inspire and motivate you to bring you game ideas to life.
                        </p>

                        </div>

                    </section>
                    
                </main>

                {/* Page footer */}
                <footer className="page-footer">

                    Last updated: {pageDate}

                </footer>

            </article>

        </Layout >
    )
}

// Export component
export default ConsultationsPage

// GraphQL query
export const pageQuery = graphql`

    query ConsultationsPageQuery {

        mdx(
            fileAbsolutePath: {
                glob: "**/content/pages/**/consultations.mdx"
            }
        ) {
            excerpt(pruneLength: 160)
            body
            frontmatter {
                title
                category
                date(formatString: "MMM D, YYYY")
                description
            }      
        }   
                     
    }
`
